<template>
  <div class="logistics-page">
    <Head title="物流详情" />
    <div class="logistics-info" v-if="info&&info.companyname">
      <!-- <p>物流状态：派送中</p> -->
      <p>承运公司：{{info.companyname}}</p>
      <p>运单编号：{{info.code}}</p>
    </div>
    <ul class="logistics-list">
      <li v-for="(item,index) in list" :key="index">
        <h5>{{item.context}}</h5>
        <p>{{item.time}}</p>
      </li>
    </ul>
    <None value="暂无信息" v-if="list.length == 0" />
  </div>
</template>

<script>
import { reqGetExpress } from "@/network/api";
import None from "components/None";
export default {
  data() {
    return {
      info: null,
      list: []
    };
  },
  components: {
    None
  },
  created() {
    this.code = this.$route.query.code || 0;
    this.getInfo();
  },
  methods: {
    getInfo() {
      reqGetExpress({
        expNo: this.code
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.info = {
          companyname: backdata.companyname,
          code:this.code
        };
        this.list = backdata.data || [];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.logistics-list {
  padding: 0.56rem 0.3rem 0.2rem 0.3rem;
  position: relative;
  box-sizing: border-box;

  li {
    position: relative;
    min-height: 0.28rem;
    margin-bottom: 0.84rem;
    padding-left: 0.52rem;
    box-sizing: border-box;

    h5 {
      color: #666666;
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    p {
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 0.17rem;
    }

    &::before {
      content: "";
      width: 0.12rem;
      height: 0.12rem;
      position: absolute;
      background: #dcdcdc;
      border-radius: 50%;
      position: absolute;
      left: 0.05rem;
      top: 0.1rem;
    }

    &:not(:last-of-type) {
      &::after {
        content: "";
        display: block;
        width: 0.02rem;
        position: absolute;
        background: #dcdcdc;
        top: 0.3rem;
        bottom: -0.84rem;
        left: 0.1rem;
      }
    }

    &:first-of-type {
      h5 {
        color: #64ada4;
      }
      &::before {
        content: "";
        width: 0.22rem;
        height: 0.22rem;
        position: absolute;
        background: #64ada4;
        border-radius: 50%;
        position: absolute;
        left: 0rem;
        top: 0.1rem;
      }
      &::after {
        top: 0.4rem;
      }
    }
  }
}
.logistics-info {
  padding: 0.4rem 0.3rem;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  position: relative;
  box-sizing: border-box;
  & > p:not(:last-of-type) {
    margin-bottom: 0.2rem;
  }
  &::after {
    content: "";
    height: 1px;
    transform: scaleY(0.5);
    position: absolute;
    bottom: 0rem;
    left: 0.3rem;
    right: 0.3rem;
    display: block;
    background: #eff0ef;
  }
}
</style>